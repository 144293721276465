// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.version-number {
  font-size: 12px;
  font-weight: bold;
  color: var(--ion-color-primary);
  opacity: 0.5;
}

@media (min-width: 769px) {
  ion-menu {
    max-width: 300px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,+BAAA;EACA,YAAA;AACF;;AAEA;EAEE;IACE,2BAAA;EAAF;AACF","sourcesContent":[".version-number {\r\n  font-size: 12px;\r\n  font-weight: bold;\r\n  color: var(--ion-color-primary);\r\n  opacity: 0.5;\r\n}\r\n\r\n@media (min-width: 769px) {\r\n\r\n  ion-menu {\r\n    max-width: 300px !important;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
