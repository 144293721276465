import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private thisStorage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this.thisStorage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public set(key: string, value: any) {
    this.thisStorage?.set(key, value);
  }

  public saveLocalData(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getLocalData(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  clearLocalData(key: string) {
    localStorage.removeItem(key);
  }
}
