import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import {
  PushNotifications,
  PushNotificationSchema,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { environment } from '../environments/environment';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public imageViewerOpen = false;

  public isPushNotificationsAvailable =
    Capacitor.isPluginAvailable('PushNotifications');

  constructor(
    private authService: AuthService,
    private router: Router,
    private alertController: AlertController,
    private toastController: ToastController,
    private storageService: StorageService
  ) {
    console.log('[AppComponent] constructor()');
  }

  async ngOnInit(): Promise<void> {
    if (this.isPushNotificationsAvailable) {
      this.setupPushNotifications();
    }
  }

  public async logOut() {
    await this.authService.logout();
    await this.router.navigateByUrl('/auth');
  }

  public getAppVersion(): string {
    return environment.appEnvironment + '-' + environment.appVersion;
  }

  public getAppName(): string {
    return environment.appName ?? 'CARO Logboek';
  }

  public async onLogout() {
    const alert = await this.alertController.create({
      header: 'Uitloggen?',
      message: 'Weet je zeker dat je uit wilt loggen?',
      buttons: [
        {
          text: 'Nee',
        },
        {
          text: 'Ja',
          handler: () => this.logOut(),
        },
      ],
    });

    await alert.present();
  }

  private setupPushNotifications(): void {
    this.checkNotificationPermissions().then((r) => console.log(r));
    this.initPushNotificationListenerToast();
  }

  private initPushNotificationListenerToast() {
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        // alert('Push received: ' + JSON.stringify(notification));
        const toast = await this.toastController.create({
          message: notification.title,
          duration: 5000,
          position: 'bottom',
          layout: 'stacked',
          // buttons: [
          //   {
          //     text: 'Toon',
          //     role: 'info',
          //     handler: () => {
          //       alert('Navigeer naar bericht');
          //     },
          //   },
          // ],
        });

        await toast.present();
      }
    );
  }

  private async checkNotificationPermissions() {
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM

        PushNotifications.register();
      } else {
        // Show some error
      }
    });
  }
}
