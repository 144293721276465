import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';

import { IonicStorageModule } from '@ionic/storage-angular';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { Network } from '@ionic-native/network/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FullscreenImageModalComponent } from './logboek/fullscreen-image-modal/fullscreen-image-modal.component';

registerLocaleData(localeNl, 'nl');

@NgModule({
  declarations: [AppComponent, FullscreenImageModalComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    IonicModule.forRoot(),
    HttpClientModule,
    IonicStorageModule.forRoot(),
    FormsModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Network,
    File,
    FileOpener,
  ],
  bootstrap: [AppComponent],
  exports: [FormsModule, HttpClientModule, FullscreenImageModalComponent],
})
export class AppModule {}
