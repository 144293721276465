/* eslint-disable no-underscore-dangle */
import {
  Injectable
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  map
} from 'rxjs/operators';
import {
  BehaviorSubject,
  Observable
} from 'rxjs';
import {
  StorageService
} from '../../services/storage.service';
import {
  environment
} from '../../environments/environment';
import {
  CaroToken
} from '../../models/carotoken.model';
import {
  User
} from '../../models/user.model';

const TOKEN_KEY = 'caro-token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  token = '';
  _user = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.checkVersion();
    this.loadToken().then(() => {});
  }

  get userId() {
    return this._user.asObservable().pipe(
      map((user) => {
        if (user) {
          return user.id;
        } else {
          return null;
        }
      })
    );
  }

  async loadToken() {
    const token = await this.storageService.getLocalData(TOKEN_KEY);
    if (token) {
      this.token = token;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  login(credentials: { username; password }): Observable<any> {
    // (`${environment.apiUrl}/auth/login`, credentials)
    return this.http.post(`${environment.apiUrl}/auth/login`, credentials);
  }

  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    this.storageService.clearLocalData(TOKEN_KEY);
    this.storageService.clearLocalData('user');
    return;
  }

  public getUserIsAuthenticated() {
    return this.isAuthenticated;
  }

  public storeToken(token: any) {
    this.storageService.saveLocalData(TOKEN_KEY, token);
    this.token = token;
    this.isAuthenticated.next(true);
  }

  public async getStoredUser(): Promise<User> {
    const user = await this.storageService.getLocalData('user');
    if (!user) {
      await this.logout();
    }
    return await this.storageService.getLocalData('user');
  }

  public decodeTokenAndStoreUserInLocalStorage(token: string): void {
    const decodedToken = this.decodeJWTToken(token);

    // Convert the decoded token to a CaroToken object, it is one on one
    const tokenObject = new CaroToken();
    tokenObject.user = decodedToken.user;
    tokenObject.username = decodedToken.username;

    // Store the token in local storage as user
    this.storageService.saveLocalData('user', tokenObject.user);
  }

  public decodeJWTToken(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  private checkVersion(): void {}
}
