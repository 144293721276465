import 'zone.js/dist/zone-error'; // Included with Angular CLI.

export const environment = {
  apiUrl: 'http://136.144.251.211:24604/api/v1',
  appEnvironment: 'dev',
  appVersion: '2.3.0',
  production: false,
  appName: 'CARO Logboek DEV',
  // iosTeamId: '',
  iosAppBundleId: 'com.softalist.carologboek.dev',
  androidAppBundleId: 'com.softalist.carologboek.dev',
};
